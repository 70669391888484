import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';

import Menu from './menu';

class Productenquiry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showStudentModal: false,
			showViewModal: false,
			showEditModal: false,
			dataList: [],
			dataView: [],
			editData: [],
			currentPage: 1,
			todosPerPage: 25,
			activePage : 1,
		}
		this.handlePageSearch = this.handlePageSearch.bind(this);
		this.loadPages = this.loadPages.bind(this);
	}
	
	componentDidMount(){
		
		this.loadPages();
	}
	
	loadPages(){
		try{
			
			this.setState({showLoader:true});
			API.post('v1/product/enquires').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});	
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handlePageSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return((upperCase(obj.productname).indexOf(v)>-1)
				|| upperCase(obj.name).indexOf(v)>-1
				|| upperCase(obj.mobile).indexOf(v)>-1);
			});
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete the Page?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/product/enquiry/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.state.dataList;
					var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});
					d.splice(index,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	handleEdit(item){
		this.setState({editData: item, showViewModal: true});
	}
	
	render(){
		
	let sno = 1;
	
		const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
		const currentTodos = this.state.dataView.slice(indexOfFirstTodo, indexOfLastTodo);
		
	  return (
	  <div>
			
			
			<LineLoader loading={this.state.showLoader} />
			
			
			<div className="pt-0" >
				
				<Row>						
					<Col md={12} >
						<Card>
							<CardBody>
								<Row>
									<Col md={9} >
										<div className="input-group">
											<Input type="text" size="sm" placeholder="Search by Name or Mobile or Email" onChange={this.handlePageSearch} />
											<span className="input-group-addon" >
												<span className="ion-android-search"></span>
											</span>
										</div>
									</Col>
									<Col md={3} >
										<div className="text-right" >
										{/*<Link to="/app/products/add" className="btn btn-info btn-sm" >
												<span className="ion-android-add"></span> Add Page
											</Link>
										&nbsp;*/}
											<Button type="button" color="warning" size="sm" title="Refresh" onClick={this.loadPages} >
												<span className="ion-ios7-reload"></span> Refresh
											</Button>
										</div>
									</Col>
								</Row>
								
								<hr style={{marginBottom: '0px'}} />
							</CardBody>
							<CardBody style={{ paddingTop: '0px' }} >
								{this.state.showLoader ? <Loader type="dot" /> : 
									<Table className="tbl" >
										<thead>
											<tr>
												<th width="80" >S.No</th>
												<th>Date</th>
												<th>Product</th>
												<th>Name</th>
												<th>Mobile</th>
												<th>Email</th>
												<th>Address</th>
												<th width="120" >Action</th>
											</tr>
										</thead>
										<tbody>
										{currentTodos.map((item,i)=>{
											return <tr key={i} >
												<td>{indexOfFirstTodo+i+1}</td>
												<td>{item.enquirydatetime}</td>
												<td>{item.productname}</td>
												<td>{item.name}</td>
												<td>{item.mobile}</td>
												<td>{item.email}</td>
												<td>{item.address}</td>
												<td align="center" >
													<Button type="button" size="sm" color="warning" title="View" onClick={this.handleEdit.bind(this,item)} >
														<span className="ion-eye"></span>
													</Button>
													&nbsp;
													<Button type="button" size="sm" color="danger" title="Remove" onClick={this.handleRemove.bind(this,item,i)} >
														<span className="ion-close-round"></span>
													</Button>
												</td>
											</tr>;
										})
										}
										</tbody>
									</Table>
								}
								
								<Row>
									<Col md={1} >
										<select className="form-control form-control-sm" onChange={(e)=>{this.setState({activePage:1,currentPage:Number(1), todosPerPage: e.target.value})}} style={{padding:'0px'}} >
											<option selected={this.state.todosPerPage=='25' ? 'selected' : ''}  value="20"> 25 </option>
											<option selected={this.state.todosPerPage=='50' ? 'selected' : ''}  value="50"> 50 </option>
											<option selected={this.state.todosPerPage=='100' ? 'selected' : ''}  value="100"> 100 </option>
										</select>
									</Col>
									<Col md={8}>
										<Pagination
										 activePage={this.state.activePage}
										 itemsCountPerPage={this.state.todosPerPage}
										 pageRangeDisplayed={3}
										 totalItemsCount={this.state.dataView.length}
										 onChange={(pageNumber)=>{
											this.setState({activePage: pageNumber});
											this.state.currentPage = Number(pageNumber); 
										 }}
										/>
									</Col>
								</Row>
								
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		
		
			<Modal isOpen={this.state.showViewModal} size="md" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showViewModal: false})}} >
					View Enquiry
				</ModalHeader>
				<ModalBody>
					<Row>
						<Col md={3} ><Label>Enquiry Date</Label></Col>
						<Col md={9} >
							<Input type="text" readOnly size="sm" value={this.state.editData.enquirydatetime} />
						</Col>
					</Row>
					
					<Row>
						<Col md={3} ><Label>Product</Label></Col>
						<Col md={9} >
							<Input type="text" readOnly size="sm" value={this.state.editData.productname} />
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={3} ><Label>Name</Label></Col>
						<Col md={9} >
							<Input type="text" readOnly size="sm" value={this.state.editData.name} />
						</Col>
					</Row>
					
					<Row className="mt-15"  >
						<Col md={3} ><Label>Mobile</Label></Col>
						<Col md={9} >
							<Input type="text" readOnly size="sm" value={this.state.editData.mobile} />
						</Col>
					</Row>
					
					<Row className="mt-15"  > 
						<Col md={3} ><Label>Email</Label></Col>
						<Col md={9} >
							<Input type="text" readOnly size="sm" value={this.state.editData.email} />
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={3} ><Label>Address</Label></Col>
						<Col md={9} >
							<Input type="textarea" rows="5" readOnly size="sm" value={this.state.editData.address} />
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={3} ><Label>Message</Label></Col>
						<Col md={9} >
							<Input type="textarea"  rows="5" readOnly size="sm" value={this.state.editData.message} />
						</Col>
					</Row>
					
					<Row className="mt-15"  > 
						<Col md={3} ><Label>Client</Label></Col>
						<Col md={9} >
							<Input type="textarea" rows="3" readOnly size="sm" value={this.state.editData.clientname} />
						</Col>
					</Row>
					
					<Row className="mt-15"  > 
						<Col md={3} ><Label>IP</Label></Col>
						<Col md={9} >
							<Input type="text" readOnly size="sm" value={this.state.editData.ip} />
						</Col>
					</Row>
					
					
				</ModalBody>
			</Modal>
			
			
		</div>
	  );
	}

}

export default Productenquiry;
