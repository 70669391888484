import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, Progress } from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase} from '../../../config';
import './index.css';
import FileBrowserLoader from './FileBrowserLoader';
import ReactFileReader from 'react-file-reader';
import {getFileTypeImage} from './functions';
import BlockLoader from './BlockLoader';

class FileBrowser extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  showStudentModal: false,
		  showEditModal: false,
		  showBrowserLoader: false,
		  showAddFolder: false,
		  showUploadFile: false,
		  createFolderText: 'Create',
		  deleteFileText: 'Delete',
		  uploadFilesText: 'Upload to server',
		  homeDirectory: './Public/uploads/demo/',
		  fileList: [],
		  originalList: [],
		  previousList: [],
		  previousPath: '.',
		  currentPath: '.',
		  currentSelectedItem: [],
		  filesToUpload: [],
		  uploadPercentage: 0,
		  multipleSelected: [],
		}
		this.loadFileList = this.loadFileList.bind(this);
		this.handleGoBackClick = this.handleGoBackClick.bind(this);
		this.handleAddFolderSubmit = this.handleAddFolderSubmit.bind(this);
		this.handleFileDelete = this.handleFileDelete.bind(this);
		this.handleSelectFiles = this.handleSelectFiles.bind(this);
		this.uploadFilesformSubmit = this.uploadFilesformSubmit.bind(this);
		this.handlePathCopy = this.handlePathCopy.bind(this);
		this.handleFileSearch = this.handleFileSearch.bind(this);
		this.handleMultipleSelected = this.handleMultipleSelected.bind(this);
	}
	
	componentDidMount(){
		
		this.loadFileList(this.state.homeDirectory);
	}
	
	emptyClick(item){
		try{
			this.setState({multipleSelected: []});
			if(item.type=='Folder')
				this.loadFileList(item.path+'/'+item.name);
		}
		catch(error){
			
		}
	}
	
	handleOnClick(item){
		try{
			
			if(this.props.multiple)
			{
				var m = this.state.multipleSelected;
				var index = m.findIndex(function(obj){
					return(obj.id==item.id);
				});
				if(index>-1) {
					m.splice(index,1);
					this.setState({multipleSelected: m, currentSelectedItem: []});
					return;
				}
				else {					
					if(upperCase(item.type)=='FOLDER') {
						this.setState({multipleSelected: []});
					}
					else {
						m.push(item);
						this.setState({multipleSelected: m});
					}
				}
			}
			
			this.setState({currentSelectedItem: item});
			
			if(this.props.fileBrowserClick && item.type!='Folder' && !this.props.multiple)
			{
				var p = Api.ImageUploadPath + item.path+'/'+item.name;
				var d = [];
				d.push({data: item, path: p});
				
				this.props.fileBrowserClick(d);
			}

			
		}
		catch(error){
			console.log(error);
		}
	}
	
	checkIn_Multiple(item){
		try{
			var index = this.state.multipleSelected.findIndex(function(obj){
				return(obj.id==item.id);
			});
			return (index>-1);
		}
		catch(error){ return false; }
	}
	
	handleMultipleSelected(){
		try{
				
			if(this.props.fileBrowserClick)
			{
				var d = [];
				this.state.multipleSelected.map((item,i)=>{
					var p = Api.ImageUploadPath + item.path+'/'+item.name;				
					d.push({data: item, path: p});
				});
				
				this.props.fileBrowserClick(d);
			}
		}
		catch(error){ }
	}
	
	loadFileList(pathName=''){
		try{
			//console.log(pathName);
			this.setState({showBrowserLoader: true, currentSelectedItem: [], multipleSelected: []});
			var form = new FormData();
			form.append('path',pathName);
			var data = form;
			API.post('v1/filemanager', data).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({originalList: res['data'].data, fileList: res['data'].data, previousPath: res['data'].directory,currentPath: res['data'].currentpath, showBrowserLoader: false });
				}
			});
		}
		catch(error){
			this.setStae({showBrowserLoader: false});
			alert('Unexpected Error');
		}
	}
	
	handleGoBackClick(){
		try{
			
			if(this.state.previousPath != '.')
			{
				console.log(this.state.currentPath);
				//console.log(this.state.previousPath);
				if(this.state.previousPath == this.state.homeDirectory){
					alert('You are not authorized to access');
					return;
				}
				this.loadFileList(this.state.previousPath);
			}
		}
		catch(error){
			
		}
	}
	
	handleFileSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.originalList.filter(function(obj){
				return((upperCase(obj.name).indexOf(v)>-1));
			});
			
			this.setState({fileList: d});
		}
		catch(error){
			
		}
	}
	
	getFileName(item){
		try{
			
			var n = item.name;
			if(n.length>8)
			{
				//return n.substring(0,20); //+'.'+item.ext;
				return n;
			}
			else
			{
				return n;
			}
		}
		catch(error){
			
		}
	}
	
	handleAddFolderSubmit(e){
		e.preventDefault();
		try{
			this.setState({createFolderText: 'Creating...' });
			API.post('v1/filemanager/folder/create',$("#frmHb_AddFolder").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({createFolderText: 'Create', showAddFolder: false });
					this.loadFileList(this.state.currentPath);
					notify.show(res['data'].message,'success');
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({createFolderText: 'Create'});
				}
			});
		}
		catch(error){
			this.setState({createFolderText: 'Create' });
		}
	}
	
	handleFileDelete(e){
		try{
			
			var c = this.state.currentSelectedItem;
			if(Object.keys(c).length<=0)
			{
				return;
			}
			
			if(c.type=='Folder')
			{
				if(!window.confirm('Do you want to delete the Folder?'))
					return false;
			}
			else
			{
				if(!window.confirm('Do you want to delete the file?'))
					return false;				
			}
			
			this.setState({deleteFileText: 'Deleting...'});
			API.post('v1/filemanager/delete', this.state.currentSelectedItem ).then(res=>{
				if(res['data'].status=='1')
				{
					this.loadFileList(this.state.currentPath);
					this.setState({deleteFileText: 'Delete'});
					notify.show(res['data'].message,'success');
				}
				else
				{
					this.setState({deleteFileText: 'Delete'});
					notify.show(res['data'].message,'error');
				}
			});
		}
		catch(error){
			this.setState({deleteFileText: 'Delete'});
		}
	}
	
	handleSelectFiles(e){
		try{
			
			let files = e.target.files;
			//console.log(files);
			this.setState({filesToUpload: files, uploadFilesText: 'Upload to server'})
			//this.setState({filesToUpload: files, uploadFilesText: 'Upload to server'});			
		}
		catch(error){
			
		}
	}
	
	uploadFilesformSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({uploadFilesText: 'Uploading to server...'});
			var d = [];
			
			var files = [];
			
			/*var b64 = this.state.filesToUpload.base64;
			var f = this.state.filesToUpload.fileList;
			for(var i=0;i<Object.keys(f).length;i++){
				var s = {
					name: f[i].name,
					base64: b64[i]
				};
				files.push(s);
			}		
			d.push({path: this.state.currentPath, item: this.state.currentSelectedItem, files: files});
			console.log(d);
			//console.log(d);
			//return;
			*/
			var $this = this;
			const config = {
			onUploadProgress: function(progressEvent) {
			  var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
			  $this.setState({uploadPercentage: percentCompleted});
			}
		  }
		  
		  const data = new FormData()
		   for(var x = 0; x<this.state.filesToUpload.length; x++) {
			   data.append('files[]', this.state.filesToUpload[x])
		   }
		   data.append('path', this.state.currentPath);
		   data.append('item', this.state.currentSelectedItem);

			API.post('v1/filemanager/upload',data, config).then(res=>{
				if(res['data'].status=='1')
				{
					
					notify.show(res['data'].message,'success');
					this.setState({uploadFilesText: 'Upload to server',filesToUpload: [],uploadPercentage: 0, showUploadFile: false});
					this.loadFileList(this.state.currentPath);
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({uploadFilesText: 'Upload to server'});
				}
			});
			
		}
		catch(error){
			console.log(error);
			alert('Unexpected Error');
			this.setState({uploadFilesText: 'Upload to server'});
		}
	}
	
	getSelectedFiles(){
		try{
			var rv = [];
			//var d = this.state.filesToUpload.fileList;
			var d = this.state.filesToUpload;
			for(var i=0;i<Object.keys(d).length;i++){
				
				rv.push(<div className="hb-filemanager-selected-files" >
					<div className="hb-filemanager-selected-files-name" >{d[i].name}</div>
					<span style={{color:'darkgrey', fontSize: '11px'}} >{d[i].size} bytes</span> &emsp;
					<span style={{color:'darkgrey', fontSize: '11px'}}  >{d[i].type}</span>
						{/*<div className="hb-filemanage-selected-files-remove" >
						<span className="ion-ios7-close-empty"></span>
						</div>*/}
				</div>);
			}
			return rv;
		}
		catch(error){
			
		}
	}
	
	handlePathCopy(containerid){
		try{
			
			var el = document.getElementById(containerid);
			var range = document.createRange();
			range.selectNodeContents(el);
			var sel = window.getSelection();
			sel.removeAllRanges();
			sel.addRange(range);
			document.execCommand('copy');
			sel.removeAllRanges();
			notify.show('Copied','success');
				
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleFileDownload(url){
		try{
			
			 var element = document.createElement('a');
			  element.setAttribute('href', url);
			  element.setAttribute('download', ' ');
			  element.setAttribute('target', '_blank');
			  element.style.display = 'none';
			  document.body.appendChild(element);
			  element.click();
			  document.body.removeChild(element);
  
		}
		catch(error){
			
		}
	}
	
	loadSingleItem(item){
		try{
			if(this.props.imageOnly ) {
				if((item.ext=='png' || item.ext=='jpg' || item.ext=='jpeg' || item.ext=='gif' || item.ext=='bmp' || upperCase(item.type)=='FOLDER' 
				|| upperCase(item.ext)=='PNG' || upperCase(item.ext)=='JPG' || upperCase(item.ext)=='JPEG' || upperCase(item.ext)=='GIF' || upperCase(item.ext)=='BMP' || upperCase(item.type)=='folder')) {
					return <Col md={2} >
						<div style={{textAlign: 'center'}} onClick={this.handleOnClick.bind(this,item)} className={this.state.currentSelectedItem.id==item.id || this.checkIn_Multiple(item) ? 'hb-filemanager-item-selected' : ''} >
							<a href="javascript:;"  style={{textDecoration: 'none', color: 'black' }} onDoubleClick={this.emptyClick.bind(this,item)} >
								{/*<span className={item.icon} style={{ fontSize: '80px' }} ></span>*/}
								<img src={getFileTypeImage(item)} style={{maxWidth: '103px', maxHeight: '58px' }} />
							<br />
							<div style={{fontSize: '11px', lineHeight: '13px' }} >{this.getFileName(item)}</div>
							</a>
						</div>
					</Col>;
				}
				else return null;
			}
			else{
				return <Col md={2} >
					<div style={{textAlign: 'center'}} onClick={this.handleOnClick.bind(this,item)} className={this.state.currentSelectedItem.id==item.id || this.checkIn_Multiple(item) ? 'hb-filemanager-item-selected' : ''} >
						<a href="javascript:;"  style={{textDecoration: 'none', color: 'black' }} onDoubleClick={this.emptyClick.bind(this,item)} >
							{/*<span className={item.icon} style={{ fontSize: '80px' }} ></span>*/}
							<img src={getFileTypeImage(item)} style={{maxWidth: '103px', maxHeight: '58px' }} />
						<br />
						<div style={{fontSize: '11px', lineHeight: '13px' }} >{this.getFileName(item)}</div>
						</a>
					</div>
				</Col>;
			}
		}
		catch(error){ }
	}
	
	
	render(){
		
	let sno = 1;
		
	  return (
	  <div id="ha-file-browser-container" style={{ height: '500px' }} >
		<Card style={{ height: '480px' }} >
			<CardBody >
			
				<BlockLoader loading={this.state.showBrowserLoader} />
				
				<Row>
					<Col md={6} >
						{this.state.previousPath== '.' || this.state.previousPath=='././.' || this.state.previousPath=='./Public' ? null : <a href="javascript:;" style={{textDecoration: 'none', color: 'black' }} onClick={this.handleGoBackClick} >
							<div style={{fontSize: '40px', lineHeight: '0px', marginTop: '-17px', float: 'left' }} >
								<span className="ion-ios7-arrow-thin-left"></span>
							</div>
						</a>
						}
						&emsp;
						<div style={{marginTop:'-27px'}} >&emsp; {this.state.currentPath}</div>
					</Col>
					<Col md={2} style={{paddingRight: '0px'}} >
						<div style={{marginTop: '-10px' }} >
							<Input type="text" placeholder="Search.." size="sm" onChange={this.handleFileSearch} />
						</div>
					</Col>
					<Col md={4} >
						<div className="text-right" style={{ marginTop: '-10px' }} >
							<button className="btn btn-secondary btn-sm" title="Refresh"  onClick={this.loadFileList.bind(this,this.state.currentPath)}  >
								<span className="ion-refresh"></span> Refresh
							</button>
							&nbsp;
							<button className="btn btn-secondary btn-sm" title="Upload"  onClick={()=>{this.setState({showUploadFile: true})}}  >
								<span className="ion-arrow-up-a"></span> Upload
							</button>
							&nbsp;
							<button className="btn btn-secondary btn-sm" title="Create New Folder" onClick={()=>{this.setState({showAddFolder: true})}}  >
								<span className="ion-android-folder"></span> New Folder
							</button>
						</div>
					</Col>
					<Col md={12} ><hr style={{marginTop: '6px'}} /></Col>					
					<Col md={9} >
						<div className="scrollbar" id="scrollbar-style-3" >
						<Row>
						{this.state.fileList.map((item,i)=> {
							return this.loadSingleItem(item);
						})							
						}
						</Row>
						</div>
					</Col>
					
					<Col md={3} >
						<div style={{borderLeft: '1px solid #dcdcdc', height: '400px'}} >
							{Object.keys(this.state.currentSelectedItem).length > 0 ?
							<Row style={{paddingLeft: '10px' }} >
								<Col md={12} >
									<table width="100%" align="center" >
										<tr><td>File Name </td></tr>
										<tr><td className="hb-filemanager-detail-content" >{this.state.currentSelectedItem.name}</td></tr>
										<tr><td>File Type</td></tr>
										<tr><td className="hb-filemanager-detail-content" >{this.state.currentSelectedItem.type}</td></tr>
										<tr><td>File Size</td></tr>
										<tr><td className="hb-filemanager-detail-content" >{this.state.currentSelectedItem.size}</td></tr>
										<tr><td>File Path</td></tr>
										<tr><td className="hb-filemanager-detail-content" >
											<div id="hb-filemanager-copypath" style={{width: '200px'}}  >{Api.ImageUploadPath}{this.state.currentSelectedItem.path}/{this.state.currentSelectedItem.name}</div>
										</td></tr>
									</table>
								</Col>
								<Col md={12} className="mt-10"  >
									<Button type="button" color="secondary" size="sm" disabled={this.state.deleteFileText!='Delete'} onClick={this.handleFileDelete} className="mt-10" >
										{this.state.deleteFileText=='Delete' ? 
										<span className="ion-close-round" ></span> :
										<span className="ion-loading-a" ></span>} &nbsp;
										{this.state.deleteFileText}
									</Button>
									&nbsp;
									<Button type="button" color="secondary" size="sm" onClick={this.handlePathCopy.bind(this,'hb-filemanager-copypath')}  className="mt-10" >
										<span className="ion-ios7-copy"></span> &nbsp; Copy File Path
									</Button>
									&nbsp;
									{this.state.currentSelectedItem.type=='Folder' ? null : 
									<Button type="button" color="secondary" size="sm" onClick={this.handleFileDownload.bind(this, Api.ImageUploadPath+this.state.currentSelectedItem.path+'/'+this.state.currentSelectedItem.name)}  className="mt-10" >
										<span className="ion-arrow-down-a"></span> &nbsp; Download
									</Button>
									}
								</Col>
							</Row>
							: null }
							{Object.keys(this.state.multipleSelected).length > 0 ? 
								<div>
									<div className="hb_selected_file_count" >{Object.keys(this.state.multipleSelected).length} Selected </div>
									<Button type="button" size="sm" color="primary" className="hb-btn-selecte-image" onClick={this.handleMultipleSelected} >
										<span className="ion-checkmark"></span> Use Selected
									</Button>
								</div>
							: null}
						</div>
					</Col>
				</Row>
			</CardBody>
		</Card>
		
		
		<Modal isOpen={this.state.showAddFolder} size="sm" backdrop={true} >
			<ModalHeader toggle={()=>{this.setState({showAddFolder:false})}} >
				Add Folder
			</ModalHeader>
			<ModalBody>
				<form action="" method="post" encType="multipart/form-data" id="frmHb_AddFolder" onSubmit={this.handleAddFolderSubmit} >
					
					<input type="hidden" name="i__parentPath" value={this.state.currentPath} />
				
					<Row>
						<Col md={12} >
							<Label>Folder Name</Label>
							<div className="input-group" >
								<Input type="text" name="i__folderName" size="sm" required />
							</div>
						</Col>
						<Col md={12} className="mt-10" >
							<div className="text-center" >
								<Button type="submit" color="secondary" size="sm" disabled={this.state.createFolderText!='Create'} >
									{this.state.createFolderText=='Create' ? 
										<span className="ion-android-checkmark" ></span> :
										<span className="ion-loading-a" ></span>} &nbsp;
									{this.state.createFolderText}
								</Button>
							</div>
						</Col>
					</Row>
				</form>
			</ModalBody>
		</Modal>
		
		
		<Modal isOpen={this.state.showUploadFile} size="larg" backdrop={true} >
			<ModalHeader toggle={()=>{this.setState({filesToUpload: [], showUploadFile:false})}} >
				Upload Files
			</ModalHeader>
			<ModalBody>
				<form action="" method="post" encType="multipart/form-data" id="frmhb_UploadFiles" onSubmit={this.uploadFilesformSubmit} >
					
					<input type="hidden" name="i__parentPath" value={this.state.currentPath} />
					<input type="hidden" name="i__currentItem" value={this.state.currentSelectedItem} />
						{Object.keys(this.state.filesToUpload).length>0 ? 
						<div>
						{this.state.uploadFilesText!='Upload to server' ? <div style={{marginBottom: '10px'}} >
								<div style={{width:'94%'}}>
									<Progress animated color="info" value={this.state.uploadPercentage} />
								</div>	
								<div style={{float: 'right',marginTop: '-16px',fontSize: '13px',marginRight: '-15px'}} >{this.state.uploadPercentage} %</div>
						</div> : null}
						
						<div className="hb-filemanage-upload-filelist" >
						{this.getSelectedFiles()}
						</div>
						<div className="mt-15" >
							<div className="text-center" >
								<Button type="submit" color="secondary" size="sm" disabled={this.state.uploadFilesText!='Upload to server'} >
									{this.state.uploadFilesText=='Upload to server' ? 
										<span className="ion-arrow-up-a" ></span> :
										<span className="ion-loading-a" ></span>} &nbsp;
									{this.state.uploadFilesText}
								</Button>
								<br />
							</div>
						</div>
						</div> 
						:
						<Row>
							<Col md={12} >
								{/*<ReactFileReader fileTypes={["*"]} base64={true} multipleFiles={true} handleFiles={this.handleSelectFiles}>
									<div className="text-center hb-filemanager-upload-container" >
										<div style={{fontSize: '50px' }} >
											<span className="ion-ios7-cloud-upload"></span>
										</div>
										<div>Select files to upload</div>
									</div>
								</ReactFileReader>*/}
								<div>
									<label for="hb_file-upload" className="hb-custom-file-upload">
										<div className="text-center hb-filemanager-upload-container" >
											<div style={{fontSize: '50px' }} >
												<span className="ion-ios7-cloud-upload"></span>
											</div>
											<div>Select files to upload</div>
										</div>
									</label>
									<input type="file" id="hb_file-upload" multiple={true} onChange={this.handleSelectFiles} />
								</div>
							</Col>
						</Row>
						}					
				</form>
			</ModalBody>
		</Modal>
		
		</div>
	  );
	}

}

export default FileBrowser;
