import React, {Component, useState} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, Collapse} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, getls, makeUrl} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';
import JoditEditor from "jodit-react";
import "jodit/build/jodit.css";

import ModalFileBrowser from '../filebrowser/ModalFileBrowser';

import NOIMAGE_WIDE from '../../../assets/images/noimage-wide.png';

import Menu from './menu';

const styles = {
	imgCloseBtn: {
		position: 'absolute',
		right: '21px',
		top: '69px',
		background: '#ff2828',
		color: '#fff',
		padding: '0px 3px',
		borderRadius: '100px',
		fontSize: '11px',
		cursor: 'pointer',
	}
}

class EditProduct extends Component {
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Save Product',
		  showStudentModal: false,
		  showFileManager: false,
		  showFileTestReportManager: false,
		  showEditModal: false,
		  isActive: true,
		  editSlug: false,
		  pageData: '',
		  inTab: false,
		  inFeatureImage: true,
		  showInHome: false,
		  showInPopular: false,
		  selectedFilename: '',
		  selectedUrl: '',
		  selectedTestReportUrl: '',
		  pageUrl: '',
		  productVariant: [],
		  pageUrl: '',
		  pid: this.props.match.params.id,
		}
		
		this.handlePageContentChange = this.handlePageContentChange.bind(this);
		this.onContentEditorChange = this.onContentEditorChange.bind(this);
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);	
		this.handleTestReportFileBrowserClick = this.handleTestReportFileBrowserClick.bind(this);	
		this.removeSelectedFeatureImage = this.removeSelectedFeatureImage.bind(this);	
		this.removeSelectedTestReport = this.removeSelectedTestReport.bind(this);	
		this.handleTitleChange = this.handleTitleChange.bind(this);	
		this.handleFormSubmit = this.handleFormSubmit.bind(this);	
		this.handleJoditChange = this.handleJoditChange.bind(this);
	}
	
	componentDidMount(){
	
		var h = document.getElementsByClassName("page-heading")[0];
			if(h!=null && h!=undefined)
				h.innerHTML = "Edit Product";
		this.loadProduct();
		
	}
	
	loadProduct(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/product/view/'+this.state.pid).then(res=>{
				if(res['data'].status=='1')
				{
					var d = res['data'].data;
					this.setState({
						isActive: d.activestatus==1,
						pageUrl: d.pageurl,
						pageData: d.pagecontent,
						inFeatureImage: (d.featureimage!='' && d.featureimage!=null && d.featureimage.length>3) ? true : true,
						selectedFilename: '',
						selectedUrl: d.featureimage!='' && d.featureimage!=null && d.featureimage.length>3 ? d.featureimage : '',
						data: d,
						productVariant: res['data'].variants,
					});
					
					this.setState({
						selectedTestReportUrl: this.getVariantValue('reportimage'),
						showInHome: this.getVariantValue('showinhome')=='1',
						showInPopular: this.getVariantValue('showinpopular')=='1',
						showLoader: false
					});
					
					console.log(this.state);
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	getVariantValue(variantName){
		try{
			var d = this.state.productVariant;
			var s = d.filter(function(obj){
				return(obj.variantname==variantName);
			});
			
			if(Object.keys(s).length>0){
				return s[0]['variantvalue'];
			}
		}
		catch(error){ }
	}
	
	 onContentEditorChange( evt ) {
		 try{
			this.setState({ pageData: evt.editor.getData() });
		 }
		 catch(error){
			 
		 }
    }


	handlePageContentChange(evt){
		try{
			
			this.setState({pageData: evt.target.value});
		}
		catch(error){
			
		}
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	handleTestReportFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.addProductVariant({variantname: 'reportimage', variantvalue: url});
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedTestReportUrl: url
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	removeSelectedFeatureImage(){
		this.setState({ selectedFilename: '',selectedUrl: '' });
	}
	
	removeSelectedTestReport(){
		
			this.addProductVariant({variantname: 'reportimage', variantvalue: ''});
		this.setState({ selectedFilename: '',selectedTestReportUrl: '' });
	}
	
	
	handleTitleChange(e){
		try{
			var str = e.target.value;
			var newString = makeUrl(str); //str.replace(/[^A-Z0-9]/ig, "-");
			this.setState({pageUrl: newString.toLowerCase()});
		}
		catch(error){
			
		}
	}
	
	addProductVariant(item){
		try{
			var d = this.state.productVariant;
			var index = d.findIndex(function(obj){
				return(obj.variantname==item.variantname);
			});
			
			if(index>-1){
				d[index]['variantvalue'] = item.variantvalue;
			}
			else
				d.push(item);
			
			this.setState({productVariant: d});
			
		}
		catch(error){ }
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({btnSave: 'Updating..', showLoader: true});
			
			API.post('v1/products/update', $("#frmEdit_Product").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					//document.getElementById("frmEdit_Product").reset();
					this.setState({btnSave: 'Save Product', showLoader: false});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({btnSave: 'Save Product', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnSave: 'Save Product', showLoader: false});
		}
	}
	
	handleJoditChange(content){
		
		this.setState({pageData: content});
	}
	
	handleCheckBoxChange(variantName, e){
		try{
			var v = e.target.checked ? '1' : '0';
			if(variantName=='showinpopular')
				this.setState({showInPopular: !this.state.showInPopular});
			else if(variantName=='showinhome')
				this.setState({showInHome: !this.state.showInHome});
				
			this.addProductVariant({variantname: variantName, variantvalue: v});
		}
		catch(error){ }
	}
	
	getField(fieldName){
		try{
			
			var d = this.state.data;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	setField(fieldName,e){
		try{
			
			var d = this.state.data;
			d[fieldName] = e.target.valuel
			this.setState({data: d});
		}
		catch(error){
			
		}
	}
	
	render(){
		
	let sno = 1;
	
		
	  return (
	  <div>
			
			<Menu />
			
			<LineLoader loading={this.state.showLoader} />
			<PageLoader loading={this.state.showLoader} />
			
			<div className="pt-50" >
			
				<form action="" method="post" encType="multipart/form-data" id="frmEdit_Product" onSubmit={this.handleFormSubmit} >
			
			
				<input type="hidden" name="i__id" value={this.getField('id')} />
				
				<Row>
					<Col md={8} >
						<Card>
							<CardBody>
								<Row>
									<Col md={12} >
										<Label>Product Name <span className="required">*</span></Label>
										<Input type="text" name="i__pageTitle" size="sm" required placeholder="Product Name" onChange={this.handleTitleChange}  defaultValue={this.getField('pagetitle')} />
									</Col>
								</Row>
								<Row className="mt-20" >
									<Col md={12} >
										<div className="input-group">
											<span className="input-group-addon" >
												{Api.SiteUrl}
											</span>
											<Input type="text" name="i__pageUrl" size="sm" required placeholder="Site Link" readOnly={!this.state.editSlug} value={this.state.pageUrl} />
											<span className="input-group-addon"  >
												<a href="javascript:;" onClick={()=>{this.setState({editSlug: !this.state.editSlug})}} >
													<span className="ion-edit"></span>
												</a>
											</span>
										</div>
									</Col>
								</Row>
								<Row className="mt-20" >
									<Col md={12} >
										<Label>About Product<span className="required">*</span>
											<div style={{position: 'absolute',top: '-1px',left: '150px'}} >
												
														<Button size="xs" color="info" onClick={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} >
															Open Filemanager
														</Button>
											</div>
										</Label>
										
										<div className="page-content-editor" >
										 <JoditEditor
												value={this.state.pageData}
												onBlur={this.handleJoditChange}
												onChange={this.handleJoditChange}
											/>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
						
						<Card>
							<CardBody>
								<CardTitle>Social Meta Details</CardTitle>
								<hr />								
								<Row>
									<Col md={12} >
										<Label>Meta Title</Label>
										<Input type="textarea" name="i__metaTitle" size="sm" rows="2" placeholder="Meta Title" value={this.getField('metatitle')} onChange={this.setField.bind(this,'metatitle')} />
									</Col>
								</Row>
								<Row className="mt-10" >
									<Col md={12} >
										<Label>Meta Description</Label>
										<Input type="textarea" name="i__metaDescription" size="sm" rows="3" placeholder="Meta Description" value={this.getField('metadescription')} onChange={this.setField.bind(this,'metadescription')} />
									</Col>
								</Row>
							</CardBody>
						</Card>
						
					</Col>
					
					<Col md={4} >
						
						<Card>
							<CardBody>
								<Row>
									<Col md={12} >
										<div className="float-left">
											 <div className="pretty p-icon p-smooth">
												<input type="checkbox" name="i__showInHome" checked={this.state.showInHome}  onChange={this.handleCheckBoxChange.bind(this,'showinhome')}  />
												<div className="state p-primary">
													<i className="icon ion-ios7-checkmark-empty"></i>
													<label>Show this product in Home Page ?</label>
												</div>
											</div>
										</div>
									</Col>
									<Col md={12} >
										<hr />
									</Col>
									<Col md={12} >
										<div className="float-left">
											 <div className="pretty p-icon p-smooth">
												<input type="checkbox" name="i__showInPopular" checked={this.state.showInPopular} onChange={this.handleCheckBoxChange.bind(this,'showinpopular')} />
												<div className="state p-primary">
													<i className="icon ion-ios7-checkmark-empty"></i>
													<label>Show this product in Popular Items ?</label>
												</div>
											</div>
										</div>
									</Col>
								</Row>
								{/*<Row className="mt-10" >
									<Col md={12} >
										<Label>Category</Label>
										<Input type="select" size="sm" name="i__pageCategory" >
											<option value="withsidebar" > Page With Sidebar </option>
											<option value="content"> Content Only </option>
										</Input>
									</Col>
								</Row>*/}
								<Row className="mt-10" >
									<Col md={12} >
										<hr />
										<div className="float-left">
											 <div className="pretty p-icon p-smooth">
												<input type="checkbox" name="i__active" defaultChecked={this.state.isActive} />
												<div className="state p-success">
													<i className="icon ion-ios7-checkmark-empty"></i>
													<label>Active</label>
												</div>
											</div>
										</div>
										<div className="float-right" >
										<Button type="submit" size="sm" color="primary" >
											{this.state.btnSave=='Save Product' ? 
												<span className="ion-android-checkmark" ></span> :
												<span className="ion-loading-a" ></span>} &nbsp;
											{this.state.btnSave}
										</Button>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
						
						{/*<Card>
							<CardBody style={{ paddingBottom: '0px' }} >
								<CardTitle>
									<div className="pretty p-icon p-smooth">
										<input type="checkbox" name="i__inTab" defaultChecked={this.state.inTab} onChange={()=>{this.setState({inTab: !this.state.inTab})}}  />
										<div className="state p-primary">
											<i className="icon ion-ios7-checkmark-empty"></i>
											<label>Display in Tab</label>
										</div>
									</div>
								</CardTitle>
								<hr style={{marginBottom:'0px'}} />
							</CardBody>
							<Collapse isOpen={this.state.inTab}>
								<CardBody style={{paddingTop: '5px', marginTop: '0px' }} >
									<Row>
										<Col md={12} >
											<Label>Tab Title</Label>
											<Input type="text" name="i__tabTitle" size="sm" />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={12} >
											<Label>Tab Order</Label>
											<Input type="number" name="i__tabOrder" size="sm" />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={12} >
											<Label>Under Menu</Label>
											<select name="i__tabMenu" className="form-control form-control-sm" >
											
											</select>
										</Col>
									</Row>
								</CardBody>
							</Collapse>
						</Card>*/}
						
						<Card>
							<CardBody style={{ paddingBottom: '0px' }} >
								<CardTitle>
									<div className="pretty p-icon p-smooth">
										<input type="checkbox" name="i__inFeatureImage" defaultChecked={this.state.inFeatureImage} onChange={()=>{this.setState({inFeatureImage: !this.state.inFeatureImage})}}  />
										<div className="state p-primary">
											<i className="icon ion-ios7-checkmark-empty"></i>
											<label>Product Image</label>
										</div>
									</div>
								</CardTitle>
								<hr style={{marginBottom:'0px'}} />
								<Collapse isOpen={this.state.inFeatureImage}>
									<div style={{marginTop: '15px', marginBottom: '5px' }} >
										<img src={this.state.selectedUrl!=null && this.state.selectedUrl!=undefined && this.state.selectedUrl.length>3 ? Api.ImageUploadPath+this.state.selectedUrl : NOIMAGE_WIDE} />
											{this.state.selectedUrl!=null && this.state.selectedUrl!=undefined && this.state.selectedUrl.length>3  ?
												<div style={styles.imgCloseBtn} onClick={this.removeSelectedFeatureImage} >
													<span className="ion-android-close"></span>
												</div>
											: null}
									</div>
									<hr style={{marginBottom:'10px'}} />
									<div className="text-center mb-10" >
									<ModalFileBrowser isOpen={this.state.showFileManager} 
													atClose={()=>{this.setState({showFileManager: !this.state.showFileManager})}}
													fileBrowserClick={this.handleFileBrowserClick}
												>
									<Button type="button" size="sm" color="secondary" block onClick={()=>{this.setState({showFileManager: !this.state.showFileManager})}} >
										<span className="ion-android-folder"></span> Browse Image
									</Button>
									</ModalFileBrowser>
									</div>
								</Collapse>
							</CardBody>
						</Card>
						
						
						<Card>
							<CardBody style={{ paddingBottom: '0px' }} >
								<CardTitle>
									<label>Test Report</label>
								</CardTitle>
								<hr style={{marginBottom:'0px'}} />
								<Collapse isOpen={true} >
									<div style={{marginTop: '15px', marginBottom: '5px' }} >
										<img src={this.state.selectedTestReportUrl!=null && this.state.selectedTestReportUrl!=undefined && this.state.selectedTestReportUrl.length>3 ? Api.ImageUploadPath+'/'+this.state.selectedTestReportUrl : NOIMAGE_WIDE} />
											{this.state.selectedTestReportUrl!=null && this.state.selectedTestReportUrl!=undefined && this.state.selectedTestReportUrl.length>3  ?
												<div style={styles.imgCloseBtn} onClick={this.removeSelectedTestReport} >
													<span className="ion-android-close"></span>
												</div>
											: null}
									</div>
									<hr style={{marginBottom:'10px'}} />
									<div className="text-center mb-10" >
									<ModalFileBrowser isOpen={this.state.showFileTestReportManager} 
													atClose={()=>{this.setState({showFileTestReportManager: !this.state.showFileTestReportManager})}}
													fileBrowserClick={this.handleTestReportFileBrowserClick}
												>
									<Button type="button" size="sm" color="secondary" block onClick={()=>{this.setState({showFileTestReportManager: !this.state.showFileTestReportManager})}} >
										<span className="ion-android-folder"></span> Browse Image
									</Button>
									</ModalFileBrowser>
									</div>
								</Collapse>
							</CardBody>
						</Card>
						
					</Col>
				</Row>
				
				<input type="hidden" name="i__pageData" value={this.state.pageData} />
				<input type="hidden" name="i__featureImage" value={this.state.selectedUrl} />
				<input type="hidden" name="i__testReport" value={this.state.selectedTestReportUrl} />
				<input type="hidden" name="i__userId" value={getls('user','id')} />
				<input type="hidden" name="i__productVariant" value={JSON.stringify(this.state.productVariant)} />
				
				</form>
				
			</div>
			
			<ModalFileBrowser isOpen={this.state.showfileBrowser} 
				atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} >
					
			</ModalFileBrowser>		
		
		</div>
	  );
	}

}

export default EditProduct;
