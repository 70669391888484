import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import {AppName, Version, ClientName} from '../../../variables';
import {LineLoader, PageLoader} from '../../../elements';
import API from '../../../config/API';
import {setls, upperCase,capitalizeFirst, groupByMultiple} from '../../../config';

import AddGallery from './AddGallery';
import EditGallery from './EditGallery';

class Gallery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showAddModal: false,
			showEditModal: false,
			dataList: [],
			dataView: [],
		}
		this.handlePageSearch = this.handlePageSearch.bind(this);
		this.loadData = this.loadData.bind(this);
	}
	
	componentDidMount(){
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true, dataList: [], dataView: [], showAddModal: false, showEditModal: false});
			API.get('v1/gallery').then(res=>{
				if(res['data'].status=='1') {
					var d = groupByMultiple(res['data'].data, function(obj){
						return[obj.refkey];
					});
					this.setState({dataList: d, dataView: d, showLoader: false});
				}
				else {
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handlePageSearch(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return(upperCase(obj[0].gallerytitle).indexOf(v)>-1);
			});
			this.setState({dataView: d});
		}
		catch(error){ }
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete the Page?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			this.setState({showLoader: true});
			API.get('v1/gallery/remove/'+item[0].refkey).then(res=>{
				if(res['data'].status=='1') {
					var d = this.state.dataList;
					/*var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});*/
					d.splice(i,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else {
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	handleGalleryEdit(item){
		this.setState({editData: item, showEditModal: true});
	}
	
	render() {
		
		let sno=1;
		
	  return (
		<div>
		
			<LineLoader loading={this.state.showLoader} />
			<PageLoader loading={this.state.showLoader} />
			
			<Card className="mt-10" >
				<CardBody>
					<Row>
						<Col md={9} >
							<div className="input-group">
								<Input type="text" size="sm" placeholder="Search by Title.." onChange={this.handlePageSearch} />
								<span className="input-group-addon" >
									<span className="ion-android-search"></span>
								</span>
							</div>
						</Col>
						<Col md={3} >
							<div className="text-right" >
								<Button type="button" size="sm" color="warning" onClick={this.loadData} >
									<span className="ion-refresh"></span>
								</Button>
								&nbsp;
								<Button type="button" size="sm" color="info" onClick={()=>{this.setState({showAddModal: true})}} >
									<span className="ion-android-add"></span> Add New
								</Button>
							</div>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={12} >
							<Table className="tbl" >
								<thead>
									<tr>
										<th width="80" >S.No</th>
										<th>Gallery Title</th>
										<th>Images</th>
										<th width="120" >Manage</th>
									</tr>
								</thead>
								<tbody>
								{this.state.dataView.map((item,i)=>{
									return <tr key={i} >
										<td>{sno++}</td>
										<td>{item[0].gallerytitle}</td>
										<td>{Object.keys(item).length} Image(s)</td>
										<td align="center" >
											<Button type="button" size="xs" color="info" title="Edit" onClick={this.handleGalleryEdit.bind(this,item)} >
												<span className="ion-edit"></span>
											</Button>
											&nbsp;
											<Button type="button" size="xs" color="danger" title="Delete" onClick={this.handleRemove.bind(this,item,i)} >
												<span className="ion-close"></span>
											</Button>
										</td>
									</tr>;
								})
								}
								</tbody>
							</Table>
						</Col>
					</Row>
					
					
				</CardBody>
			</Card>
			
			
			<Modal isOpen={this.state.showAddModal} size="xl" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showAddModal: false})}} >
					Add Gallery
				</ModalHeader>
				<ModalBody>
					<AddGallery  atFinish={this.loadData} />	
				</ModalBody>
			</Modal>
			
			<Modal isOpen={this.state.showEditModal} size="xl" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showEditModal: false})}} >
					Edit Gallery
				</ModalHeader>
				<ModalBody>
					<EditGallery editData={this.state.editData} atFinish={this.loadData} />	
				</ModalBody>
			</Modal>
			
			
		</div>
	  );
	}

}

export default Gallery;
