import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';

import AddSlider from './AddSlider';
import EditSlider from './EditSlider';

class SliderPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  showAddSlider: false,
		  showEditSlider: false,
		  editData: [],
		  dataList: [],
		  dataView: [],
		}
		this.handlePageSearch = this.handlePageSearch.bind(this);
		this.loadSliders = this.loadSliders.bind(this);
	}
	
	componentDidMount(){
		
		this.loadSliders();
	}
	
	loadSliders(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/sliders/').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});	
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handlePageSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return(upperCase(obj.title).indexOf(v)>-1);
			});
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete the Slider?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			
			this.setState({showLoader: true});
			API.post('v1/slider/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.state.dataList;
					var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});
					d.splice(index,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	handleEdit(item,e){
		try{
			
			this.setState({
				editData: item,
				showEditSlider: true
			});
		}
		catch(error){
			
		}
	}
	
	render(){
		
	let sno = 1;
		
	  return (
	  <div>
			
			
			<LineLoader loading={this.state.showLoader} />
			
			
			<div className="pt-10" >
				
				<Row>						
					<Col md={12} >
						<Card>
							<CardBody>
								<Row>
									<Col md={9} >
										<div className="input-group">
											<Input type="text" size="sm" placeholder="Search by Title.." onChange={this.handlePageSearch} />
											<span className="input-group-addon" >
												<span className="ion-android-search"></span>
											</span>
										</div>
									</Col>
									<Col md={3} >
										<div className="text-right" >
											<Button size="sm" color="info" onClick={()=>{this.setState({showAddSlider: true})}} >
												<span className="ion-android-add"></span> Add Slider
											</Button>
											&nbsp;
											<Button type="button" color="warning" size="sm" title="Refresh" onClick={this.loadSliders} >
												<span className="ion-ios7-reload"></span>
											</Button>
										</div>
									</Col>
								</Row>
								
								<hr style={{marginBottom: '0px'}} />
							</CardBody>
							<CardBody style={{ paddingTop: '0px' }} >
								{this.state.showLoader ? <Loader type="dot" /> : 
									<Table className="tbl" >
										<thead>
											<tr>
												<th width="80" >S.No</th>
												<th>Slider</th>
												<th>Order</th>
												<th width="120" >Action</th>
											</tr>
										</thead>
										<tbody>
										{this.state.dataView.map((item,i)=>{
											return <tr key={i} className={item.activestatus!='1' ? 'bg-danger-light' : ''} >
												<td>{sno++}</td>
												<td>
													<img src={Api.ImageUploadPath+item.url} height={100} />
													<br />
													Title : {item.title}
												</td>
												<td align="center">{item.slideorder}</td>
												<td align="center" >
													
													<Button type="button" size="sm" color="primary" title="Edit" onClick={this.handleEdit.bind(this,item)} >
														<span className="ion-edit"></span>
													</Button>
													&nbsp;
													<Button type="button" size="sm" color="danger" title="Remove" onClick={this.handleRemove.bind(this,item,i)} >
														<span className="ion-close-round"></span>
													</Button>
												</td>
											</tr>;
										})
										}
										</tbody>
									</Table>
								}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
			
			  <Modal isOpen={this.state.showAddSlider} size="lg" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showAddSlider: false})}} >
					Add Slider
				</ModalHeader>
				<ModalBody>
					
					<AddSlider atClose={()=>{this.setState({showAddSlider: false})}} atFinish={this.loadSliders} />
										
				</ModalBody>
			</Modal>
			
			<Modal isOpen={this.state.showEditSlider} size="lg" backdrop={true} >
				<ModalHeader toggle={()=>{this.setState({showEditSlider: false})}} >
					Edit Slider
				</ModalHeader>
				<ModalBody>
					
					<EditSlider atClose={()=>{this.setState({showEditSlider: false})}} atFinish={this.loadSliders} editData={this.state.editData} />
										
				</ModalBody>
			</Modal>
		
		</div>
	  );
	}

}

export default SliderPage;
