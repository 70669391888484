import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, momentDate} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';

import Menu from './menu';

class Products extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showStudentModal: false,
			showEditModal: false,
			dataList: [],
			dataView: [],
			currentPage: 1,
			todosPerPage: 25,
			activePage : 1,
		}
		this.handlePageSearch = this.handlePageSearch.bind(this);
		this.loadPages = this.loadPages.bind(this);
	}
	
	componentDidMount(){
		
		this.loadPages();
	}
	
	loadPages(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/products/').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});	
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handlePageSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return(upperCase(obj.pagetitle).indexOf(v)>-1);
			});
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete the Page?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/page/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.state.dataList;
					var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});
					d.splice(index,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	render(){
		
	let sno = 1;
	
		const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
		const currentTodos = this.state.dataView.slice(indexOfFirstTodo, indexOfLastTodo);
		
	  return (
	  <div>
			
			
			<LineLoader loading={this.state.showLoader} />
			
			<Menu />
			
			<div className="pt-50" >
				
				<Row>						
					<Col md={12} >
						<Card>
							<CardBody>
								<Row>
									<Col md={9} >
										<div className="input-group">
											<Input type="text" size="sm" placeholder="Search by Product Name.." onChange={this.handlePageSearch} />
											<span className="input-group-addon" >
												<span className="ion-android-search"></span>
											</span>
										</div>
									</Col>
									<Col md={3} >
										<div className="text-right" >
											<Link to="/app/products/add" className="btn btn-info btn-sm" >
												<span className="ion-android-add"></span> Add Product
											</Link>
											&nbsp;
											<Button type="button" color="warning" size="sm" title="Refresh" onClick={this.loadPages} >
												<span className="ion-ios7-reload"></span>
											</Button>
										</div>
									</Col>
								</Row>
								
								<hr style={{marginBottom: '0px'}} />
							</CardBody>
							<CardBody style={{ paddingTop: '0px' }} >
								{this.state.showLoader ? <Loader type="dot" /> : 
									<Table className="tbl" >
										<thead>
											<tr>
												<th width="80" >S.No</th>
												<th>Image</th>
												<th>Product Name</th>
												<th width="120" >Action</th>
											</tr>
										</thead>
										<tbody>
										{currentTodos.map((item,i)=>{
											return <tr key={i} >
												<td>{indexOfFirstTodo+i+1}</td>
												<td>
												{item.featureimage!=null && item.featureimage!='' && item.featureimage.length > 3 ?
													<img src={Api.ImageUploadPath + item.featureimage} style={{width:'90px'}} />
												: <div className="text-center">No Image</div>}
												</td>
												<td>
													{item.pagetitle} <br />
													<span style={{fontSize:'11px'}} >Created on : {momentDate(item.idate,'DD-MM-YYYY')}</span> |
													<span style={{fontSize:'11px'}} >URL : <a href={Api.SiteUrl+item.pageurl} target="_blank" >
														{Api.SiteUrl}{item.pageurl}
													</a></span>
												</td>
												<td align="center" >
													<Link to={"/app/products/edit/"+item.id+"/"+item.pageurl} className="btn btn-info btn-sm"  >
														<span className="ion-edit"></span>
													</Link>	
													&nbsp;
													<Button type="button" size="sm" color="danger" title="Remove" onClick={this.handleRemove.bind(this,item,i)} >
														<span className="ion-close-round"></span>
													</Button>
												</td>
											</tr>;
										})
										}
										</tbody>
									</Table>
								}
								
								<Row>
									<Col md={1} >
										<select className="form-control form-control-sm" onChange={(e)=>{this.setState({activePage:1,currentPage:Number(1), todosPerPage: e.target.value})}} style={{padding:'0px'}} >
											<option selected={this.state.todosPerPage=='25' ? 'selected' : ''}  value="20"> 25 </option>
											<option selected={this.state.todosPerPage=='50' ? 'selected' : ''}  value="50"> 50 </option>
											<option selected={this.state.todosPerPage=='100' ? 'selected' : ''}  value="100"> 100 </option>
										</select>
									</Col>
									<Col md={8}>
										<Pagination
										 activePage={this.state.activePage}
										 itemsCountPerPage={this.state.todosPerPage}
										 pageRangeDisplayed={3}
										 totalItemsCount={this.state.dataView.length}
										 onChange={(pageNumber)=>{
											this.setState({activePage: pageNumber});
											this.state.currentPage = Number(pageNumber); 
										 }}
										/>
									</Col>
								</Row>
								
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		
		</div>
	  );
	}

}

export default Products;
