
export default {
	top: [
			{ name: 'Dashboard', url: '/app/home', icon: 'Home', ionicon: 'ion-grid', },
			/*{ name: 'Students Reports', icon: 'File', ionicon: 'ion-clipboard', allowed: ['admin', 'webadmin'], 
			  children: [
				{ name: 'Students List', url: '/app/reports/studentslist',},
				{ name: 'Address List', url: '/app/reports/addresslist', },
				{ name: 'Caste List', url: '/app/reports/castelist', },
				{ name: 'Export List', url: '/app/reports/exportlist', },
				{ name: 'Custom Export', url: '/app/reports/customexport', },
				{ name: 'Statistic Report', url: '/app/reports/statisticreport', },
			  ],
			},
			{ name: 'Student Atten. Cert.', url: '/app/students/oldstudents/attedancecertificate', icon: 'Home', ionicon: 'ion-document', allowed:['admin'] },
			{ name: 'Messages', icon: 'File', ionicon: 'ion-ios7-chatboxes',allowed: ['admin'], 
			  children: [
				{ name: 'Templates', url: '/app/message/template', },
				{ name: 'Send to Students', url: '/app/message/tostudents', },
				{ name: 'Send to Staffs', url: '/app/message/tostaffs', },
				{ name: 'SMS Report', url: '/app/message/reports', }
			  ],
			},*/
			//{ name: 'Posts', url: '/app/posts', icon: 'Home', ionicon: 'ion-arrow-graph-up-right',  allowed:['webadmin'] },
			{ name: 'Snacks', url: '/app/products', icon: 'Home', ionicon: 'ion-ios7-copy-outline',  allowed:['webadmin'] },
			{ name: 'Trading', url: '/app/trading', icon: 'Home', ionicon: 'ion-ios7-copy-outline',  allowed:['webadmin'] },
			{ name: 'Enquiry', url: '/app/productenquiry', icon: 'Home', ionicon: 'ion-ios7-help-outline',  allowed:['webadmin'] },
			{ name: 'Pages', url: '/app/pages', icon: 'Home', ionicon: 'ion-ios7-copy-outline',  allowed:['webadmin'] },
			//{ name: 'News & Events', url: '/app/newsandevents', icon: 'Home', ionicon: 'ion-clipboard',  allowed:['webadmin'] },
			{ name: 'Sliders', url: '/app/manageslider', icon: 'Home', ionicon: 'ion-ios7-albums',  allowed:['webadmin'] },
			//{ name: 'Gallery', url: '/app/gallery', icon: 'Home', ionicon: 'ion-ios7-photos-outline',  allowed:['webadmin'] },
			{ name: 'Menus', url: '/app/menus', icon: 'Home', ionicon: 'ion-drag',  allowed:['webadmin'] },
			//{ name: 'Users', url: '/app/users', icon: 'Home', ionicon: 'ion-person',  allowed:['webadmin'] },
			{ name: 'File Manager', url: '/app/filemanager', icon: 'Home', ionicon: 'ion-ios7-folder', allowed: ['webadmin', 'staff', 'deptadmin', 'researchadmin', 'naacadmin','filemanager'] },
			//{ name: 'My Profile', url: '/app/myprofile/', icon: 'Home', ionicon: 'ion-person', allowed: ['staff'] },
			{ name: 'Settings', url: '/app/settings', icon: 'Home', ionicon: 'ion-gear-a',  allowed:['webadmin'] },
		],
	bottom: [
	]
}