import ErrorPage from './pages/404';

import Home from './app/home';
import FileManager from './app/home/FileManager';
import Pages from './app/pages';
import AddPage from './app/pages/AddPage';
import EditPage from './app/pages/EditPage';
import PageCategory from './app/pages/PageCategory';
import Newsandevents from './app/Newsandevents';
import AddNewsandEvents from './app/Newsandevents/AddNewsandEvents';
import EditNewsandEvents from './app/Newsandevents/EditNewsandEvents';
import Announcement from './app/Newsandevents/Announcement';
import MenuList from './app/menulist';
import MenuOrder from './app/menulist/menuorder';

import Products from './app/products';
import AddProduct from './app/products/AddProduct';
import EditProduct from './app/products/EditProduct';
import Productenquiry from './app/productenquiry';

import Trading from './app/trading';
import AddTrading from './app/trading/AddTrading';
import EditTrading from './app/trading/EditTrading';

import SliderPage from './app/sliderpage';
import Gallery from './app/Gallery';
import MyProfile from './app/profile';

import Settings from './app/settings';
import LoginUserProfile from './app/settings/LoginUserProfile';

import CKEditor from 'ckeditor4-react';
CKEditor.editorUrl = 'https://cdn.ckeditor.com/4.13.1/full/ckeditor.js';

const pageList = [
  { name: 'Dashboard', path: '/app/home', component: Home },
  { name: 'Profile', path: '/app/settings/myprofile', component: LoginUserProfile },
  { name: 'File Manager', path: '/app/filemanager', component: FileManager },
  { name: 'Pages', path: '/app/pages', component: Pages, allowed: ['webadmin'] },
  { name: 'News & Events', path: '/app/newsandevents/events', component: Newsandevents, allowed: ['webadmin'] },
  { name: 'Add Event', path: '/app/newsandevents/addevent', component: AddNewsandEvents, allowed: ['webadmin'] },
  { name: 'Edit Event', path: '/app/newsandevents/editevent/:id/:slug', component: EditNewsandEvents, allowed: ['webadmin'] },
  { name: 'Announcement', path: '/app/newsandevents', component: Announcement, allowed: ['webadmin'] },
  { name: 'Add Page', path: '/app/pages/add', component: AddPage, allowed: ['webadmin'] },
  { name: 'Edit Page', path: '/app/pages/edit/:id/:url', component: EditPage, allowed: ['webadmin'] },
  { name: 'Page Categories', path: '/app/pages/categories', component: PageCategory },
  { name: 'Products', path: '/app/products', component: Products, allowed: ['webadmin'] },
  { name: 'New Product', path: '/app/products/add', component: AddProduct, allowed: ['webadmin'] },
  { name: 'Edit Product', path: '/app/products/edit/:id/:url', component: EditProduct, allowed: ['webadmin'] },
  { name: 'Product Enquiry', path: '/app/productenquiry', component: Productenquiry, allowed: ['webadmin'] },
  { name: 'Menus', path: '/app/menus', component: MenuList, allowed: ['webadmin']  },
  { name: 'Menu Order', path: '/app/menus/order', component: MenuOrder , allowed: ['webadmin'] },
  { name: 'Sliders', path: '/app/manageslider', component: SliderPage , allowed: ['webadmin'] },
  { name: 'Gallery', path: '/app/gallery', component: Gallery , allowed: ['webadmin'] },
  { name: 'Settings', path: '/app/settings', component: Settings , allowed: ['webadmin'] },
  { name: 'Trading', path: '/app/trading', component: Trading, allowed: ['webadmin'] },
  { name: 'New Trading', path: '/app/trading/add', component: AddTrading, allowed: ['webadmin'] },
  { name: 'Edit Trading', path: '/app/trading/edit/:id/:url', component: EditTrading, allowed: ['webadmin'] },
];

export default pageList;
